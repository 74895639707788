import { Component, OnInit, PipeTransform, Type } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DecimalPipe } from '@angular/common';
import { FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { catchError, map, startWith } from 'rxjs/operators';
import { CategoryService } from 'src/app/Service/category.service';

interface Category {
  name: string;
  description: string;
  image: string;
  subCategory: any,
  id: string,
  moreCategory:any,
}

const CATEGORIES: Category[] = [
  { name: 'Mathematics', image: 'https://i.ibb.co/4NttvD6/file-20180226-140213-yox11e.jpg',
    description: '17075200', subCategory : [
      {id: 1, name: '1 one'},
      {id: 2, name: '2 two'},
      {id: 3, name: '3 three'},
    ], id: '', 
    moreCategory : 0
  },
  { name: 'Arts', image: 'https://i.ibb.co/4NttvD6/file-20180226-140213-yox11e.jpg',
    description: '17075200', subCategory : [
      {id: 1, name: '1 one'},
      {id: 2, name: '2 two'},
      {id: 3, name: '3 three'},
    ] , id: '',
    moreCategory: 0
  },
];



function search(text: string, pipe: PipeTransform): Category[] {
  return CATEGORIES.filter(category => {
    const term = text.toLowerCase();
    return category.name.toLowerCase().includes(term)
        // || pipe.transform(category.subCategory).includes(term)
        || pipe.transform(category.description).includes(term);
  });
}
@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css']
})
export class CategoriesComponent implements OnInit {

  public searchFilter: any = '';
  searchText;
  
  _categories;

  categories$: Observable<Category[]>;
  filter = new FormControl('');
  categoryForm: FormGroup;
  closeResult = '';
  selectedValue = 2;
  imageURL: string;
  _loader = false;

  companies = [
    { id: 1, name: 'Company 1'},
    { id: 2, name: 'Company 2'},
    { id: 3, name: 'Company 3'}
  ];

  constructor(pipe: DecimalPipe, private modalService: NgbModal, private fb: FormBuilder,private categoryService:CategoryService) {
    this.categories$ = this.filter.valueChanges.pipe(
      startWith(''),
      map(text => search(text, pipe))
    );
  }


 ngOnInit() {
  this.categoryForm = this.fb.group({
   name: [''],
   subCategory: [''],
   description: [''],
   id: [null],
   moreCategory: [null],
  });

  this._loader = true;
  this.categoryService.getAllMainCategory().subscribe(
    res => {
      this._categories  = res.body
      console.log(res.body);
      this._loader = false;
    }
  )
 }
 openModal(targetModal, category, i) {
  this.modalService.open(targetModal, {
  //  centered: true,
   backdrop: 'static'
  });
 
  this.categoryForm.patchValue({
   name: category.name,
   subCategory: category.subCategory,
   description: category.description,
   id: category.id,
   moreCategory: category.MoreCategory
  });
 }

 addModal(addProfileModal) {
  this.modalService.open(addProfileModal, {
  //  centered: true,
   backdrop: 'static'
  });
 
  this.categoryForm.patchValue({
   name: [''],
   subCategory: [''],
   description:[''],
   id: [null],
   moreCategory: [null],
  });
 }

 deleteModal(deleteModals, i){
  this.modalService.open(deleteModals, {
    //  centered: true,
    //  backdrop: 'static'
    });
   console.log(`deleted`, i)
 }

   // Image Preview
   showPreview(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.categoryForm.patchValue({
      avatar: file
    });
    this.categoryForm.get('avatar').updateValueAndValidity()

    // File Preview
    const reader = new FileReader();
    reader.onload = () => {
      this.imageURL = reader.result as string;
    }
    reader.readAsDataURL(file)
  }
 onSubmit() {
  this.modalService.dismissAll();
  this.categoryService.updateMainCategory(this.categoryForm.getRawValue()).subscribe(

    res => {
      console.log(res.body)
      // if(res.body['token'] != null){     
      // }
      
    }
  )
  console.log(this.categoryForm.getRawValue());
 }

}

