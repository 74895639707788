import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from "../../environments/environment";


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) { }

  LogIn(apiData){

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post(this.baseUrl + 'adminLogin', apiData, {

      headers: httpHeaders,
      observe: 'response'
    });
  }

  loggedIn(){
    console.log("test");
    return !!localStorage.getItem('token');
  }

  logout() {
    localStorage.removeItem('token');
    //sessionStorage.clear();
  }
}
