import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) { }

  getAllMainCategory(){
    
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.get(this.baseUrl + 'getAllMain', {

      headers: httpHeaders,
      observe: 'response'
    });
  }

  getAllSubCategory(){
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.get(this.baseUrl + 'getAllSubCategories', {

      headers: httpHeaders,
      observe: 'response'
    });
  }

  getAllSubCategoryById(id){
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.get(this.baseUrl + 'getSubCategoryByMainCategoryId/'+id, {

      headers: httpHeaders,
      observe: 'response'
    });
  }

  updateMainCategory(apiData){
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.put(this.baseUrl + 'updateMainCategory',apiData, {

      headers: httpHeaders,
      observe: 'response'
    });
  }


  addSubCategory(apiData){
    // const httpHeaders = new HttpHeaders({
    //   // 'Content-Type': 'application/json'
    //   'Content-Type': 'multipart/form-data',
    //   'Accept': 'application/json'
      
    // });
    const httpHeaders = new HttpHeaders();
    httpHeaders.append('Content-Type', 'multipart/form-data');
    httpHeaders.append('Accept', 'application/json');

    return this.http.post(this.baseUrl + 'addSubCategory',apiData, {

      headers: httpHeaders,
      observe: 'response'
    });
  }

  updateSubCategory(apiData){
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'multipart/form-data'
    });

    console.log(apiData.cat_image)
    return this.http.post(this.baseUrl + 'updateSubCategory',apiData, {
      headers: httpHeaders,
      observe: 'response'
    });
  }

  deleteSubCategory(id){
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.delete(this.baseUrl + 'deleteSubCategory/'+id, {

      headers: httpHeaders,
      observe: 'response'
    });
  }

}
