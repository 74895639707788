import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.css']
})
export class ImageComponent implements OnInit {

  constructor(private http:HttpClient) { }

  filedata:any;
    /* File onchange event */
    fileEvent(e){
        this.filedata = e.target.files[0];
        console.log( this.filedata)
    }
    onSubmitform() {
       
      var myFormData = new FormData();
      const headers = new HttpHeaders();
      headers.append('Content-Type', 'multipart/form-data');
      headers.append('Accept', 'application/json');
      myFormData.append('image', this.filedata);
      myFormData.append('name', "this.filedata");
      /* Image Post Request */
      this.http.post('http://127.0.0.1:8000/api/uploadimage', myFormData, {
      headers: headers
      }).subscribe(data => {
        console.log("---data---")
        console.log(data)
      });  
  
  }

  ngOnInit(): void {
  }

}
