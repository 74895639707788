import { Component, OnInit, PipeTransform, Type } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DecimalPipe } from '@angular/common';
import { FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { catchError, map, startWith } from 'rxjs/operators';
import { CategoryService } from 'src/app/Service/category.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-subcategories',
  templateUrl: './subcategories.component.html',
  styleUrls: ['./subcategories.component.css']
})
export class SubcategoriesComponent implements OnInit {
  baseUrl = environment.baseUrl;
  baseUrlforImg = environment.baseUrlforImg;
  filedata:any;

  public searchFilter: any = '';
  searchText;
  
  _categories;

  categories$;
  filter = new FormControl('');
  categoryForm: FormGroup;
  closeResult = '';
  selectedValue = 2;
  imageURL: string;
  mainCategorySelectedId;
  deleteId;
  imageFile;

  companies;
  _loader = false;

  constructor(pipe: DecimalPipe, private modalService: NgbModal, private fb: FormBuilder,private categoryService:CategoryService, private http: HttpClient,private router: Router) {

  }


 ngOnInit() {
  this.categoryForm = this.fb.group({
   name: [''],
   mainCategoryId: [''],
   description: [''],
   id: [null],
   moreCategory: [null],
  });
  
  this._loader = true;
  this.getAllSubCategory();
  this.categoryService.getAllMainCategory().subscribe(
    res => {
      this.companies  = res.body
      console.log(res.body);
      this._loader = false;
    }
  )

 }
 openModal(targetModal, category, i) {

  this.modalService.open(targetModal, {
  //  centered: true,
   backdrop: 'static'
  });
 
  this.categoryForm.patchValue({
    name: category.name,
    mainCategoryId: category.mainCategoryId,
    description: category.description,
    id: category.id,
    moreCategory: category.MoreCategory
  });
  this.mainCategorySelectedId = category.mainCategoryId;
 }
 getAllSubCategory(){
  this.categoryService.getAllSubCategory().subscribe(

    res => {
      this._categories  = res.body
      console.log(res.body);
    }
  )
 }

 addModal(addProfileModal) {
  this.modalService.open(addProfileModal, {
  //  centered: true,
   backdrop: 'static'
  });
 
  this.categoryForm.patchValue({
   name: [''],
   mainCategoryId: [''],
   description:[''],
   id: [null],
   moreCategory: [null],
  });
 }

 deleteModal(deleteModals, i){
  this.modalService.open(deleteModals, {
    //  centered: true,
    //  backdrop: 'static'
    });
    this.deleteId = i;
   //console.log(`deleted`, i)
 }

 deleteSubCategory(){
  this.modalService.dismissAll();
  this.categoryService.deleteSubCategory(this.deleteId).subscribe(
    res => {
      console.log(res.body)
    }
  )
  this.getAllSubCategory();
 }

   // Image Preview
   showPreview(event) {
    this.filedata = event.target.files[0];
    // const file = (event.target as HTMLInputElement).files[0];

    // File Preview
    const reader = new FileReader();
    reader.onload = () => {
      this.imageURL = reader.result as string;
    }
    reader.readAsDataURL(this.filedata)
  }

  onMainCategoryChange(newValue) {
    this.mainCategorySelectedId = newValue;
    
  }

//  onSubmit() {
//   this.modalService.dismissAll();
//   if(this.categoryForm.getRawValue().id.length == 1){
//     console.log(this.filedata)
//     // console.log(this.imageFile.name)
//     // console.log(this.categoryForm.getRawValue())
//     this.categoryService.addSubCategory({
//       // "mainCategoryId":"this.mainCategorySelectedId",
//       "mainCategoryId": this.mainCategorySelectedId,
//       "name": "this.categoryForm.getRawValue().name",
//       "description": "this.categoryForm.getRawValue().description",
//       "moreCategory" : "0",
//       "image" : this.filedata
//     }).subscribe(
//       res => {
//         console.log(res.body)
//       }
//     )
//   }
//   else{
//      //console.log(this.categoryForm.getRawValue())
//     this.categoryService.updateSubCategory({
//       "id": this.categoryForm.getRawValue().id,
//       "mainCategoryId":this.mainCategorySelectedId,
//       "name": this.categoryForm.getRawValue().name,
//       "description": this.categoryForm.getRawValue().description,
//       "cat_image": this.imageFile
//     }).subscribe(
//       res => {
//         console.log(res.body)
//       }
//     )
//   }
  
//  }


  // new method of insert and update by shan!
 onSubmitform() {

  var myFormData = new FormData();
  const headers = new HttpHeaders();
  headers.append('Content-Type', 'multipart/form-data');
  headers.append('Accept', 'application/json');
  if(this.categoryForm.getRawValue().id.length == 1){
    myFormData.append('mainCategoryId', this.mainCategorySelectedId);
    myFormData.append('name', this.categoryForm.getRawValue().name);
    myFormData.append('description', this.categoryForm.getRawValue().description);
    myFormData.append('moreCategory', "0");
    myFormData.append('image', this.filedata);
    /* Image Post Request */
    this.http.post(this.baseUrl + 'addSubCategory', myFormData, {
    headers: headers
    }).subscribe(data => {
      console.log(data)
    });  
  } else {
    myFormData.append('id', this.categoryForm.getRawValue().id);
    myFormData.append('mainCategoryId', this.mainCategorySelectedId);
    myFormData.append('name', this.categoryForm.getRawValue().name);
    myFormData.append('description', this.categoryForm.getRawValue().description);
    myFormData.append('moreCategory', "0");
    myFormData.append('image', this.filedata);
    /* Image Post Request */
    this.http.post(this.baseUrl + 'updateSubCategory', myFormData, {
    headers: headers
    }).subscribe(data => {
      console.log(data)
    });  
  }

  this.imageURL = ''
  this.modalService.dismissAll();
  this.getAllSubCategory();
}


}
