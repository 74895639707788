import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class QuestionService {

  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) { }

  getAllQuestionsWithAnswers(){
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.get(this.baseUrl + 'getAllQuestionsWithAnswers', {

      headers: httpHeaders,
      observe: 'response'
    });
  }

  addQuestion(apiData){
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post(this.baseUrl + 'addQuestionAnswers',apiData, {

      headers: httpHeaders,
      observe: 'response'
    });
  }
  updateQuestion(apiData){
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.put(this.baseUrl + 'updateQuestionAnswers',apiData, {

      headers: httpHeaders,
      observe: 'response'
    });
  }

  deleteQuestion(id){
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.delete(this.baseUrl + 'deleteQuestion/'+id, {

      headers: httpHeaders,
      observe: 'response'
    });
  }


}
