import { Component, OnInit, PipeTransform, Type } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DecimalPipe } from '@angular/common';
import { FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { QuestionService } from 'src/app/Service/question.service';
import { QuizService } from 'src/app/Service/quiz.service';

interface Quiz {
  quiz: string;
  questionName: string;
  answer1: string;
  answer2: string;
  answer3: string;
  answer4: string;
  answer5: string;
  correctAnswer: string;
}

const QUIZES: Quiz[] = [
  { quiz: 'A/L', questionName: '17075200', answer1: '40 Min', answer2: '40 Min', answer3: '40 Min', answer4: '40 Min', answer5: '40 Min', correctAnswer: '4' }, 
  { quiz: 'A/L', questionName: '17075200', answer1: '40 Min', answer2: '40 Min', answer3: '40 Min', answer4: '40 Min', answer5: '40 Min', correctAnswer: '4' },

];

function search(text: string, pipe: PipeTransform): Quiz[] {
  return QUIZES.filter(quiz => {
    const term = text.toLowerCase();
    return quiz.questionName.toLowerCase().includes(term)
        // || pipe.transform(quiz.subCategory).includes(term)
        // || pipe.transform(quiz.quiz).includes(term);
  });
}

@Component({
  selector: 'app-question-and-answer',
  templateUrl: './question-and-answer.component.html',
  styleUrls: ['./question-and-answer.component.css']
})
export class QuestionAndAnswerComponent implements OnInit {


  public searchFilter: any = '';
  searchText;

  quizes$: Observable<Quiz[]>;
  filter = new FormControl('');
  quizForm: FormGroup;
  closeResult = '';
  selectedValue = 2;
  imageURL: string;

  _questions;
  deleteId;
  
  quizes;
  _loader = false;

  subCategories = [
    { id: 1, name: 'subCategories 1'},
    { id: 2, name: 'subCategories 2'},
    { id: 3, name: 'subCategories 3'}
  ];
  examTypes = [
    { id: 1, name: 'examTypes 1'},
    { id: 2, name: 'examTypes 2'},
    { id: 3, name: 'examTypes 3'}
  ];

  radioButtons =[
    {Id: 4, name: 'Radio4'},
    {Id: 5, name: 'Radio5'},
    ]

  constructor(pipe: DecimalPipe, private modalService: NgbModal, private fb: FormBuilder,private questionService:QuestionService,private quizService:QuizService) {
    this.quizes$ = this.filter.valueChanges.pipe(
      startWith(''),
      map(text => search(text, pipe))
    );
  }


 ngOnInit() {
    this.quizForm = this.fb.group({
    quiz: [''],
    questionName: [''],
    answer1: [''],
    answer2: [''],
    answer3: [''],
    answer4: [''],
    answer5: [''],
    correctAnswer: [''],
    questionId: [''],
    answer1Id:[''],
    answer2Id:[''],
    answer3Id:[''],
    answer4Id:[''],
    answer5Id:['']
    });

    this._loader = true;
  
    this.questionService.getAllQuestionsWithAnswers().subscribe(

      res => {
        this._questions = res.body;
        //console.log(this._questions)
        //this._categories  = res.body
        console.log(res.body);
        this._loader = false;
      }
    )

    this.quizService.getAllQuiz().subscribe(
      res => {
        this.quizes = res.body;
        
        console.log(res.body);
      }
    )
 


 }
 openModal(targetModal, quiz, i) {
  this.modalService.open(targetModal, {
  //  centered: true,
   backdrop: 'static'
  });
 
  this.quizForm.patchValue({
    
    quiz: quiz.quizId,
    questionName: quiz.name,
    answer1: quiz.options.length >= 1 ? quiz.options[0].name:"",
    answer2: quiz.options.length >= 2 ? quiz.options[1].name:"",
    answer3: quiz.options.length >= 3 ? quiz.options[2].name:"",
    answer4: quiz.options.length >= 4 ? quiz.options[3].name:"",
    answer5: quiz.options.length >= 5 ? quiz.options[4].name:"",
    correctAnswer: quiz.correctAnswer,
    answer1Id: quiz.options.length >= 1 ? quiz.options[0].id:"",
    answer2Id: quiz.options.length >= 2 ? quiz.options[1].id:"",
    answer3Id: quiz.options.length >= 3 ? quiz.options[2].id:"",
    answer4Id: quiz.options.length >= 4 ? quiz.options[3].id:"",
    answer5Id: quiz.options.length >= 5 ? quiz.options[4].id:"",
    questionId:quiz.id
  });
 }

 addModal(addProfileModal) {
  this.modalService.open(addProfileModal, {
  //  centered: true,
   backdrop: 'static'
  });
 
  this.quizForm.patchValue({
    quiz: [''],
    questionName: [''],
    answer1: [''],
    answer2: [''],
    answer3: [''],
    answer4: [''],
    answer5: [''],
    correctAnswer: [''],
    questionId:[''],
    answer1Id:[''],
    answer2Id:[''],
    answer3Id:[''],
    answer4Id:[''],
    answer5Id:['']
   
  });
 }
 
 deleteModal(deleteModals, i){
  this.modalService.open(deleteModals, {
    //  centered: true,
    //  backdrop: 'static'
    });
   console.log(`deleted`, i)
   this.deleteId = i;
 }

 deleteQuestion(){
    this.questionService.deleteQuestion(this.deleteId).subscribe(
      res => {
        console.log(res.body)
      }
    )
    this.modalService.dismissAll();
 }

 onSubmit() {
  this.modalService.dismissAll();
  //console.log("res:", this.quizForm.getRawValue());
  if(this.quizForm.getRawValue().questionId.length == 1){
    
    this.questionService.addQuestion({
      "name":this.quizForm.getRawValue().questionName,
      "quizId": this.quizForm.getRawValue().quiz.id,
      "answer1": this.quizForm.getRawValue().answer1,
      "answer2": this.quizForm.getRawValue().answer2,
      "answer3": this.quizForm.getRawValue().answer3,
      "answer4": this.quizForm.getRawValue().answer4,
      "answer5": this.quizForm.getRawValue().answer5.length == 1 ? null:this.quizForm.getRawValue().answer5,
      "correctAnswer": this.quizForm.getRawValue().correctAnswer
    
    }).subscribe(
      res => {
        console.log(res.body)
      }
    );
  }
  else{
    //console.log("res:", this.quizForm.getRawValue());
    this.questionService.updateQuestion({
      "questionId": this.quizForm.getRawValue().questionId,
      "name":this.quizForm.getRawValue().questionName,
      "quizId": this.quizForm.getRawValue().quiz,
      "answer1": this.quizForm.getRawValue().answer1,
      "answer1Id": this.quizForm.getRawValue().answer1Id,
      "answer2": this.quizForm.getRawValue().answer2,
      "answer2Id": this.quizForm.getRawValue().answer2Id,
      "answer3": this.quizForm.getRawValue().answer3,
      "answer3Id": this.quizForm.getRawValue().answer3Id,
      "answer4": this.quizForm.getRawValue().answer4,
      "answer4Id": this.quizForm.getRawValue().answer4Id,
      "answer5": this.quizForm.getRawValue().answer5,
      "answer5Id": this.quizForm.getRawValue().answer5Id,
      "correctAnswer": this.quizForm.getRawValue().correctAnswer
      
    }).subscribe(
      res => {
        console.log(res.body)
      }
    )
  }
 }

 showDiv ;
 radioButtonChanged(event){
  this.showDiv = event;
}

}
