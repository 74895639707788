import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class QuizService {

  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) { }


  getAllQuiz(){
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.get(this.baseUrl + 'getAllQuiz', {

      headers: httpHeaders,
      observe: 'response'
    });
  }

  addQuiz(apiData){
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post(this.baseUrl + 'addQuiz',apiData, {

      headers: httpHeaders,
      observe: 'response'
    });
  }

  updateQuiz(apiData){
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.put(this.baseUrl + 'updateQuiz',apiData, {

      headers: httpHeaders,
      observe: 'response'
    });
  }

  deleteQuizId(id){
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.delete(this.baseUrl + 'deleteQuiz/'+id, {

      headers: httpHeaders,
      observe: 'response'
    });
  }
}
