import { Component, OnInit, PipeTransform, Type } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DecimalPipe } from '@angular/common';
import { FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { QuizService } from 'src/app/Service/quiz.service';
import { CategoryService } from 'src/app/Service/category.service';

export interface Quiz {
  MainCategoryId: string;
  SubCategoryId: any;
  SecondSubCategoryId:any;
  name: string;
  time: string;
  type: string;
  created_at: any;
  updated_at:any;
  
}

var _quiz: Quiz[] =[];
var qu: Quiz;
var QUIZES;
var mainCategoryList;

function search(text: string, pipe: PipeTransform): Quiz[] {
  return _quiz.filter(quizs => {
    const term = text.toLowerCase();
    return quizs.name.toLowerCase().includes(term)
        // || pipe.transform(quiz.subCategory).includes(term)
        // || pipe.transform(quiz.mainCategory).includes(term);
  });
}

@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.css']
})
export class QuizComponent implements OnInit {
  public searchFilter: any = '';
  searchText;
  quizes$: Observable<Quiz[]>;
  filter = new FormControl('');
  quizForm: FormGroup;
  closeResult = '';
  selectedValue = 2;
  imageURL: string;
  mainCategoryId;
  
  _QUIZES;

  mainCategorySelectedId;
  subCategorySelectedId;
  mainCategories;
  subCategories;
  deleteId;
  _loader = false;

  examTypes = [
    { id: 1, name: 'Easy'},
    { id: 2, name: 'Medium'},
    { id: 3, name: 'Hard'}
  ];

  constructor(pipe: DecimalPipe, private modalService: NgbModal, private fb: FormBuilder,private quizService:QuizService,private categoryService:CategoryService) {
    
  }


 ngOnInit() {
  
  this.quizService.getAllQuiz().subscribe(

    res => {
      QUIZES = res.body;
      QUIZES.forEach(function (value) {
        qu = value;
        _quiz.push(qu);

        
      });
      console.log(QUIZES)
      //console.log(QUIZES);
      this._QUIZES = QUIZES
      //console.log(this._QUIZES);

    }
  )
  
  this._loader = true;
  this.categoryService.getAllMainCategory().subscribe(
    res => {
      this.mainCategories = res.body;
      console.log(this.mainCategories);
      this._loader = false;
    }
  )

  

  this.quizForm = this.fb.group({
   mainCategory: [''],
   subCategory: [''],
   name: [''],
   examType: [''],
   examTime: [''],
   id: [null],
  });
 }


onMainCategoryChange(newValue) {
  console.log(newValue);
  this.mainCategorySelectedId = newValue;
  this.categoryService.getAllSubCategoryById(newValue).subscribe(
    res => {
      this.subCategories = res.body;
      //console.log(res.body) 
    }
  )
}

onSubCategoryChange(val) {
  this.subCategorySelectedId = val;
  console.log(this.subCategorySelectedId);
}


 openModal(targetModal, quiz, i) {
  this.modalService.open(targetModal, {
  //  centered: true,
   backdrop: 'static'
  });
  
  
  this.categoryService.getAllSubCategoryById(quiz.MainCategoryId).subscribe(
    res => {
      this.subCategories = res.body;
      //console.log(res.body);
    }
  )

  this.quizForm.patchValue({
    mainCategory: quiz.MainCategoryId,
    subCategory: quiz.SubCategoryId,
    name: quiz.name,
    examType: quiz.type,
    examTime: quiz.time,
    id: quiz.id,
  });
  this.mainCategorySelectedId = quiz.MainCategoryId;
  this.subCategorySelectedId = quiz.SubCategoryId;
 }

 addModal(addProfileModal) {
  this.modalService.open(addProfileModal, {
  //  centered: true,
   backdrop: 'static'
  });
 
  this.quizForm.patchValue({
    mainCategory: [''],
    subCategory: [''],
    name: [''],
    examType: [''],
    examTime: [''],
    id: [null],
  });
 }
 
 deleteModal(deleteModals, i){
  this.modalService.open(deleteModals, {
  
    //  centered: true,
    //  backdrop: 'static'
    });
    this.deleteId = i;
 }



 deleteQuiz(){
  console.log(this.deleteId);
  this.quizService.deleteQuizId(this.deleteId).subscribe(
    res => {
      console.log(res.body)
    }
  )
  this.modalService.dismissAll();
 }

 onSubmit() {
  this.modalService.dismissAll();
  
  if(this.quizForm.getRawValue().id.length == 1){
    
    this.quizService.addQuiz({
      "name":this.quizForm.getRawValue().name,
      "type": this.quizForm.getRawValue().examType,
      "time": this.quizForm.getRawValue().examTime,
      "MainCategoryId": this.mainCategorySelectedId,
      "SubCategoryId": this.subCategorySelectedId
  
    }).subscribe(
      res => {
        console.log(res.body)
      }
    )
  }
  else{
    //console.log("res:", this.quizForm.getRawValue());
    this.quizService.updateQuiz({
      "id": this.quizForm.getRawValue().id,
      "name":this.quizForm.getRawValue().name,
      "type": this.quizForm.getRawValue().examType,
      "time": this.quizForm.getRawValue().examTime,
      "MainCategoryId": this.mainCategorySelectedId,
      "SubCategoryId": this.subCategorySelectedId
  
    }).subscribe(
      res => {
        console.log(res.body)
      }
    )
  }
 }

}
