import { AuthGuard } from './../../auth.guard';
import { Routes } from '@angular/router';
import { CategoriesComponent } from 'src/app/pages/categories/categories.component';
import { ImageComponent } from 'src/app/pages/image/image.component';
import { QuestionAndAnswerComponent } from 'src/app/pages/question-and-answer/question-and-answer.component';
import { QuizComponent } from 'src/app/pages/quiz/quiz.component';
import { SubcategoriesComponent } from 'src/app/pages/subcategories/subcategories.component';
import { UserComponent } from 'src/app/pages/user/user.component';

export const AdminLayoutRoutes: Routes = [
    { path: 'categories',           component: CategoriesComponent },
    { path: 'subcategories',           component: SubcategoriesComponent },
    { path: 'image',           component: ImageComponent },
    { path: 'users',           component: UserComponent },
    { path: 'quiz',           component: QuizComponent },
    { path: 'questions',           component: QuestionAndAnswerComponent },
];
