import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ClipboardModule } from 'ngx-clipboard';

import { AdminLayoutRoutes } from './admin-layout.routing';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UserComponent } from 'src/app/pages/user/user.component';
import { QuizComponent } from 'src/app/pages/quiz/quiz.component';
import { QuestionAndAnswerComponent } from 'src/app/pages/question-and-answer/question-and-answer.component';
import { SearchFilterPipe } from 'src/app/search-filter.pipe';
import { CategoriesComponent } from 'src/app/pages/categories/categories.component';
import { SubcategoriesComponent } from 'src/app/pages/subcategories/subcategories.component';
import { LoaderComponent } from 'src/app/pages/loader/loader.component';
// import { ToastrModule } from 'ngx-toastr';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(AdminLayoutRoutes),
    FormsModule,
    HttpClientModule,
    NgbModule,
    ClipboardModule,
    ReactiveFormsModule
  ],
  declarations: [
    UserComponent,
    QuizComponent,
    QuestionAndAnswerComponent,
    CategoriesComponent,
    SubcategoriesComponent,
    SearchFilterPipe,
    LoaderComponent
  ]
})

export class AdminLayoutModule {}
